import React from 'react';

import './EditableTiles.scss';

const EditableTiles = ({ ...props }) => {
  return (
    <div className="tile__container">
      <div
        className={`${
          props.editable_type === 'add' ? 'add__message' : 'delete__message'
        }`}
      >
        {props.message}
      </div>
      <div className="tile__title">{props.title}</div>
      {props.id && props.type === 'edit' ? (
        <div>{props.id}</div>
      ) : (
        <input
          type="text"
          name="main_dealer"
          placeholder="New Sponsor"
          value={props.id}
          onChange={(event) => props.handleChange(event, props.index)}
        />
      )}
      <h3> {props.subtitle} </h3>
      {props.sub_dealers.map((subdealer, idx) => (
        <div className="subdealer" key={idx}>
          {subdealer && props.type === 'edit' ? (
            <div>{subdealer}</div>
          ) : (
            <input
              type="text"
              name="sub_dealer"
              placeholder="new dealer"
              value={subdealer}
              onChange={(event) => props.handleChange(event, props.index, idx)}
            />
          )}
        </div>
      ))}
      <div className="edit__button">
        {!props.requested_edit && props.type === 'edit' && (
          <button
            onClick={(event) => props.handleEdit(event, props.index, 'add')}
          >
            Add
          </button>
        )}
      </div>
      <div className="delete__button">
        {!props.requested_edit && props.type === 'edit' && (
          <button
            onClick={(event) => props.handleEdit(event, props.index, 'delete')}
          >
            Delete
          </button>
        )}
      </div>
      {props.new_subdealers &&
        props.new_subdealers.map(({ name }, idx) => (
          <div key={idx}>
            <input
              type="text"
              name="dealer"
              placeholder="new dealer"
              value={name}
              required
              onChange={(event) => props.handleChange(event, props.index, idx)}
            />
          </div>
        ))}
      {props.requested_edit && props.editable_type !== 'delete' ? (
        <button
          onClick={(event) => props.handleEdit(event, props.index, 'add')}
        >
          Add
        </button>
      ) : (
        props.type === 'add' && (
          <button onClick={(event) => props.handleEdit(event, props.index)}>
            Add
          </button>
        )
      )}
    </div>
  );
};

export default EditableTiles;
