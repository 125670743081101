import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CALogo from './../assets/ca_logo.png';
import './Login.scss';

import { cognitoSignIn } from './../auth/cognito';

const Login = ({ setAuthenticatedUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const onSubmit = async (event) => {
    event.preventDefault();
    cognitoSignIn({ email, password }, callback);
    setEmail('');
    setPassword('');
  };

  const callback = {
    error: setError,
    setAuthentication: setAuthenticatedUser
  };

  const handleChange = () => (event) => {
    event.preventDefault();
    event.target.name === 'email'
      ? setEmail(event.target.value)
      : setPassword(event.target.value);
  };

  return (
    <div className="login__section__container">
      <div className="logo__section">
        <div className="ca__logo__container">
          <img src={CALogo} alt="Constellation Agency Logo" />
        </div>
        <div className="login__admin__text">
          <h1>DGAC Administration</h1>
          <form onSubmit={onSubmit}>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              onChange={handleChange()}
              value={email}
              required
            />
            <br />
            <label>Password:</label>
            <input
              type="password"
              name="password"
              onChange={handleChange()}
              value={password}
              required
            />
            <br />
            <button type="submit">login</button>
            <p>Forgot password? Contact the Dev team for support</p>
            <p>
              First time login here? click <Link to="/verify">here</Link> to
              signup
            </p>
          </form>
          {error && <p>{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Login;
