import React, { useState } from 'react';
import { getIdToken } from './../auth/cognito';
import EditableTiles from './EditableTiles';

import './AddDealers.scss';

const AWS_API_ADMIN = process.env.REACT_APP_AWS_ADMIN_API;

const AddDealers = () => {
  const template = {
    id: '',
    sub_dealers: ['']
  };

  const [newDealers, setNewDealers] = useState([template]);
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [message, setMessage] = useState('');

  const handleNewDealer = (event) => {
    event.preventDefault();
    setNewDealers([...newDealers, template]);
  };

  const handleEdit = (event, dealerIdx) => {
    event.preventDefault();
    setNewDealers((prevState) => [
      ...prevState.slice(0, dealerIdx),
      {
        id: prevState[dealerIdx].id,
        sub_dealers: [...prevState[dealerIdx].sub_dealers, '']
      },
      ...prevState.slice(dealerIdx + 1)
    ]);
  };
  const handleOnChange = (event, dealerIdx, subdealerIdx) => {
    event.persist();
    if (event.target.name === 'main_dealer') {
      setNewDealers((prevState) => [
        ...prevState.slice(0, dealerIdx),
        {
          id: event.target.value,
          sub_dealers: prevState[dealerIdx].sub_dealers
        },
        ...prevState.slice(dealerIdx + 1)
      ]);
    } else {
      setNewDealers((prevState) => [
        ...prevState.slice(0, dealerIdx),
        {
          id: prevState[dealerIdx].id,
          sub_dealers: [
            ...prevState[dealerIdx].sub_dealers.slice(0, subdealerIdx),
            event.target.value,
            ...prevState[dealerIdx].sub_dealers.slice(subdealerIdx + 1)
          ]
        }
      ]);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsWaitingResponse(true);
    try {
      const token = await getIdToken();
      await fetch(`${AWS_API_ADMIN}/dealers`, {
        method: 'POST',
        body: JSON.stringify(newDealers),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        }
      });
      setIsWaitingResponse(false);
      setMessage('Success!');
    } catch (err) {
      setIsWaitingResponse(false);
      setMessage('Error: Log out and try again. Contact Dev for more support');
    }
  };
  return (
    <div className="add__dealers__section">
      <p>To discard changes, refresh page</p>
      <p>
        Executive Sponsor has to be <i>unique</i> among <b>all</b> present
        sponsors
      </p>
      {!isWaitingResponse && !message ? (
        <div className="add__dealers__container">
          {newDealers.map(({ id, sub_dealers }, idx) => (
            <EditableTiles
              key={idx}
              id={id}
              index={idx}
              type="add"
              sub_dealers={sub_dealers}
              title="Executive Sponsor"
              subtitle="Dealers"
              handleChange={handleOnChange}
              handleEdit={handleEdit}
            />
          ))}
          <div className="add__dealer__section">
            <button onClick={(event) => handleNewDealer(event)}>
              Add dealer
            </button>
          </div>
          <div className="add__dealer__section">
            <button onClick={(event) => onSubmit(event)}>Submit</button>
          </div>
        </div>
      ) : isWaitingResponse && !message ? (
        <div className="loader"></div>
      ) : (
        message && <div className="success__message">{message}</div>
      )}
    </div>
  );
};

export default AddDealers;
