import React from 'react';

import './PressTile.scss';

const PressTile = ({ image_url, link, text, publisher }) => {
  return (
    <div className="press__tile">
      <div className="press__image__container">
        <img src={image_url} alt="" />
      </div>
      <div className="press__info__container">
        <div className="press__publisher">{publisher}</div>
        <div className="press__text">
          {text}{' '}
          {link && (
            <a href={link} target="_blank" rel="noopener noreferrer">
              Read more
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default PressTile;
