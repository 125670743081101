import React, { useState, useEffect } from 'react';
import { getIdToken } from './../../auth/cognito';
import './VolunteersDashboard.scss';

const AWS_API_CLIENT = process.env.REACT_APP_AWS_CLIENT_API;
const AWS_API_ADMIN = process.env.REACT_APP_AWS_ADMIN_API;

const VolunteersDashboard = () => {
  const [volunteers, setVolunteers] = useState([]);
  const [deletedVolunteers, setDeletedVolunteers] = useState([]);
  const [newVolunteer, setNewVolunteer] = useState('');
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${AWS_API_CLIENT}/volunteers`);
        await response.json().then((res) => {
          res.sort((obj1, obj2) => (obj1.name > obj2.name ? 1 : -1));
          const sortedVolunteers = res.map((volunteer) => ({
            ...volunteer,
            requested_delete: false
          }));
          setVolunteers(sortedVolunteers);
        });
      } catch (err) {
        setIsWaitingResponse(false);
        setMessage(
          'Error: Log out and try again. Contact Dev for more support'
        );
      }
    };
    fetchData();
  }, []);

  const onDelete = (event, idx) => {
    event.preventDefault();
    setVolunteers((prevState) => [
      ...prevState.slice(0, idx),
      {
        ...prevState[idx],
        requested_delete: true
      },
      ...prevState.slice(idx + 1)
    ]);
    setDeletedVolunteers((prevState) => [...prevState, volunteers[idx]]);
  };

  const onChange = (event) => {
    event.persist();
    setNewVolunteer(event.target.value);
  };

  const confirm = (deletedLenght) => {
    if (
      window.confirm(
        `You are about to delete ${deletedLenght} volunteers. Do you want to proceed?`
      )
    )
      return true;
    return false;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsWaitingResponse(true);
    if (confirm(deletedVolunteers.length)) {
      try {
        const token = await getIdToken();
        await fetch(`${AWS_API_ADMIN}/volunteer`, {
          method: 'POST',
          body: JSON.stringify({
            deletedVolunteers,
            newVolunteer
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`
          }
        });
        setIsWaitingResponse(false);
        setMessage('Success!');
      } catch (err) {
        setIsWaitingResponse(false);
        setMessage(
          'Error: Log out and try again. Contact Dev for more support'
        );
      }
    }
  };
  return (
    <div className="volunteers__section">
      <h1>Volunteers Administration</h1>
      <p>
        Volunteers will be deleted <i>only</i> when you click submit
      </p>
      <p>Refresh page to abort deleting a volunteer</p>
      {!isWaitingResponse && !message ? (
        <div className="add__delete__volunteers__section">
          <div className="delete__volunteers__section">
            {volunteers.map(({ name, requested_delete }, idx) => {
              return (
                !requested_delete && (
                  <div className="volunteer__container" key={name}>
                    <div className="name">{name}</div>
                    <div className="delete__volunteer__button">
                      <button onClick={(event) => onDelete(event, idx)}>
                        Delete
                      </button>
                    </div>
                  </div>
                )
              );
            })}
          </div>
          <div className="add__volunteers__section">
            <h2>Add volunteer</h2>
            <input
              type="text"
              name="new_volunteer"
              placeholder="new volunteer"
              value={newVolunteer}
              onChange={(event) => onChange(event)}
            />
          </div>
          <div className="submit__volunteers__button">
            <button onClick={(event) => onSubmit(event)}>Submit</button>
          </div>
        </div>
      ) : isWaitingResponse && !message ? (
        <div className="loader"></div>
      ) : (
        message && <div className="success__message">{message}</div>
      )}
    </div>
  );
};

export default VolunteersDashboard;
