import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js';

const POOL_DATA = {
  UserPoolId: process.env.REACT_APP_AMAZON_USER_POOL_ID,
  ClientId: process.env.REACT_APP_AMAZON_COGNITO_CLIENT_ID
};

const userPool = new CognitoUserPool(POOL_DATA);

export const cognitoSignUp = (credentials, callback) => {
  const authData = {
    Username: credentials.email,
    Password: credentials.verificationCode
  };

  const authDetails = new AuthenticationDetails(authData);
  const userData = {
    Username: credentials.email,
    Pool: userPool
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.authenticateUser(authDetails, {
    onSuccess(result) {},
    onFailure(err) {},
    newPasswordRequired(userAttributes, requiredAttributes) {
      delete userAttributes.email_verified;
      cognitoUser.completeNewPasswordChallenge(
        credentials.password,
        userAttributes,
        {
          onSuccess(result) {
            callback.setAuthentication(true);
          },
          onFailure(err) {
            callback.error(err.message);
          }
        }
      );
    }
  });
};

export const cognitoSignIn = (credentials, callback) => {
  const authData = {
    Username: credentials.email,
    Password: credentials.password
  };

  const authDetails = new AuthenticationDetails(authData);
  const userData = {
    Username: credentials.email,
    Pool: userPool
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.authenticateUser(authDetails, {
    onSuccess(result) {
      callback.setAuthentication(true);
    },
    onFailure(err) {
      callback.error(err.message);
    }
  });
};

export const getCurrentUser = () => {
  return userPool.getCurrentUser();
};

export const isUserAuthenticated = async (setAuthenticatedUser) => {
  const user = await getCurrentUser();
  if (!user) {
    setAuthenticatedUser(false);
  } else {
    user.getSession((err, session) => {
      if (err) {
        setAuthenticatedUser(false);
      } else {
        if (session.isValid()) {
          setAuthenticatedUser(true);
        } else {
          setAuthenticatedUser(false);
        }
      }
    });
  }
};

export const cognitoSignOut = async (setAuthenticatedUser) => {
  try {
    getCurrentUser().signOut();
    setAuthenticatedUser(false);
  } catch (err) {
    setAuthenticatedUser(false);
  }
};

export const getIdToken = async (setAuthenticatedUser) => {
  const user = getCurrentUser();
  if (!user) {
    setAuthenticatedUser(false);
    return null;
  }
  return await user.getSession((err, session) => {
    if (err) {
      setAuthenticatedUser(false);
      return null;
    }
    return session.getIdToken().getJwtToken();
  });
};
