import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';
import { getIdToken } from './../auth/cognito';
import Tile from './PressTile';

import './PressAdd.scss';

const AWS_API_ADMIN = process.env.REACT_APP_AWS_ADMIN_API;

const PressAdd = () => {
  const [imagePreview, setImagePreview] = useState({ file: '', path: '' });
  const [inputText, setInputText] = useState('');
  const [inputLink, setInputLink] = useState('');
  const [inputPublisher, setInputPublisher] = useState('');
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [message, setMessage] = useState('');

  const imageHandler = (event) => {
    event.persist();

    let reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview({
        file: event.target.files[0],
        path: reader.result
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleInputChange = () => (event) => {
    event.preventDefault();

    if (event.target.name === 'text') {
      setInputText(event.target.value);
    } else if (event.target.name === 'link') {
      setInputLink(event.target.value);
    } else {
      setInputPublisher(event.target.value.toUpperCase());
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!inputLink.includes('https://')) {
      alert('Link does not contain the prefix "https://"');
      setInputLink('');
      return;
    }

    try {
      setIsWaitingResponse(true);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 501,
        useWebWorker: false
      };

      const compressedFile = await imageCompression(imagePreview.file, options);

      let reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview({
          file: compressedFile,
          path: reader.result
        });
      };
      reader.readAsDataURL(compressedFile);

      const token = await getIdToken();
      await fetch(`${AWS_API_ADMIN}/press`, {
        method: 'POST',
        body: JSON.stringify({
          image: {
            name: imagePreview.file.name,
            type: imagePreview.file.type,
            file: imagePreview.path
          },
          text: inputText,
          link: inputLink,
          publisher: inputPublisher
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        }
      });
      setIsWaitingResponse(false);
      setMessage('Success!');
    } catch (err) {
      setIsWaitingResponse(false);
      setMessage(
        'Error. Image might not meet standards. Try again or contact DEV'
      );
    }
  };

  return (
    <div className="press__add__section">
      <p>Link should include https:// prefix!</p>
      {!isWaitingResponse && !message ? (
        <div className="image__display__preview">
          <div className="image__user__input">
            <input type="file" onChange={(event) => imageHandler(event)} />
            <Tile
              image_url={imagePreview.path}
              link={inputLink}
              publisher={inputPublisher}
              text={inputText}
            />
          </div>
          {imagePreview.file && (
            <div className="text__user__input">
              <input
                type="text"
                name="publisher"
                onChange={handleInputChange()}
                value={inputPublisher}
                placeholder="input publisher"
              />
              <input
                type="text"
                name="text"
                onChange={handleInputChange()}
                value={inputText}
                placeholder="input text"
              />
              <input
                type="text"
                name="link"
                onChange={handleInputChange()}
                value={inputLink}
                placeholder="input link"
              />
              {inputText && inputLink && inputPublisher && (
                <button onClick={(event) => onSubmit(event)}>Submit</button>
              )}
            </div>
          )}
        </div>
      ) : isWaitingResponse && !message ? (
        <div className="loader"></div>
      ) : (
        message && <div className="success__message">{message}</div>
      )}
    </div>
  );
};

export default PressAdd;
