import React, { useState } from 'react';

import NavBar from './NavBar';
import DealersDashboard from './dashboards/DealersDashboard';
import VolunteersDashboard from './dashboards/VolunteersDashboard';
import PressDashboard from './dashboards/PressDashboard';
import SponsorsDashboard from './dashboards/SponsorsDashboard';
import StatsDashboard from './dashboards/StatsDashboard';

import './Dashboard.scss';

const Greeting = () => (
  <div className="greeting__container">
    <h1>Welcome to Administration Interface</h1>
    <h2>To Edit, click one of the buttons above</h2>
    <h3>Version 1.5</h3>
  </div>
);

const Dashboard = ({ setAuthenticatedUser }) => {
  const [displayDealers, setDisplayDealers] = useState(false);
  const [displayVolunteers, setDisplayVolunteers] = useState(false);
  const [displayGreeting, setDisplayGreeting] = useState(true);
  const [displayPress, setDisplayPress] = useState(false);
  const [displaySponsors, setDisplaySponsors] = useState(false);
  const [displayStats, setDisplayStats] = useState(false);

  return (
    <div>
      <NavBar
        setAuthenticatedUser={setAuthenticatedUser}
        setDisplayDealers={setDisplayDealers}
        setDisplayGreeting={setDisplayGreeting}
        setDisplayVolunteers={setDisplayVolunteers}
        setDisplayPress={setDisplayPress}
        setDisplaySponsors={setDisplaySponsors}
        setDisplayStats={setDisplayStats}
      />
      <div className="dashboard__container">
        {displayGreeting && <Greeting />}
        {displayDealers && <DealersDashboard />}
        {displayVolunteers && <VolunteersDashboard />}
        {displayPress && <PressDashboard />}
        {displaySponsors && <SponsorsDashboard />}
        {displayStats && <StatsDashboard />}
      </div>
    </div>
  );
};

export default Dashboard;
