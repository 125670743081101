import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';

import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Verify from './components/Verify';

import { isUserAuthenticated } from './auth/cognito';

const App = () => {
  const [authenticatedUser, setAuthenticatedUser] = useState(false);
  isUserAuthenticated(setAuthenticatedUser);

  return (
    <div className="App">
      <Switch>
        <Route
          exact
          path="/login"
          render={() =>
            authenticatedUser ? (
              <Redirect to="/" />
            ) : (
              <Login setAuthenticatedUser={setAuthenticatedUser} />
            )
          }
        />
        <Route
          exact
          path="/"
          render={() =>
            authenticatedUser ? (
              <Dashboard setAuthenticatedUser={setAuthenticatedUser} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/verify"
          render={() =>
            authenticatedUser ? (
              <Dashboard setAuthenticatedUser={setAuthenticatedUser} />
            ) : (
              <Verify setAuthenticatedUser={setAuthenticatedUser} />
            )
          }
        />
      </Switch>
    </div>
  );
};

export default App;
