import React, { useState, useEffect } from 'react';
import imageCompression from 'browser-image-compression';
import { getIdToken } from '../../auth/cognito';

import Tile from '../SponsorTile';

import './SponsorsDashboard.scss';

const AWS_API_CLIENT = process.env.REACT_APP_AWS_CLIENT_API;
const AWS_API_ADMIN = process.env.REACT_APP_AWS_ADMIN_API;

const SponsorsDashbord = () => {
  const [currentSponsors, setCurrentSponsors] = useState([]);
  const [imagePreview, setImagePreview] = useState({ file: '', path: '' });
  const [homepageLogo, setHomepageLogo] = useState({ file: '', path: '' });
  const [company, setCompany] = useState('');
  const [inputText, setInputText] = useState(['', '']);
  const [inputLink, setInputLink] = useState('');
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${AWS_API_CLIENT}/sponsors`);
        await response.json().then((res) => setCurrentSponsors(res));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  });

  const homepageImageHandler = (event) => {
    event.persist();

    let reader = new FileReader();

    reader.onloadend = () => {
      setHomepageLogo({
        file: event.target.files[0],
        path: reader.result
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const imageHandler = (event) => {
    event.persist();

    let reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview({
        file: event.target.files[0],
        path: reader.result
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleInputChange = (idx) => (event) => {
    event.persist();

    if (event.target.name === 'company') {
      setCompany(event.target.value);
    } else if (event.target.name === 'text') {
      if (idx === 0) {
        setInputText((prevState) => [event.target.value, prevState[1]]);
      } else setInputText((prevState) => [prevState[0], event.target.value]);
    } else {
      setInputLink(event.target.value);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!inputLink.includes('https://')) {
      alert('Link does not contain the prefix "https://"');
      setInputLink('');
      return;
    }

    try {
      setIsWaitingResponse(true);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: false
      };

      let compressedFile = await imageCompression(imagePreview.file, options);

      let reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview({
          file: compressedFile,
          path: reader.result
        });
      };
      reader.readAsDataURL(compressedFile);

      compressedFile = await imageCompression(homepageLogo.file, options);

      reader = new FileReader();
      reader.onloadend = () => {
        setHomepageLogo({
          file: compressedFile,
          path: reader.result
        });
      };
      reader.readAsDataURL(compressedFile);

      const token = await getIdToken();
      await fetch(`${AWS_API_ADMIN}/sponsor`, {
        method: 'POST',
        body: JSON.stringify({
          image: {
            name: imagePreview.file.name,
            type: imagePreview.file.type,
            file: imagePreview.path
          },
          text: inputText,
          link: inputLink,
          name: company,
          home_page_logo: {
            name: homepageLogo.file.name,
            type: homepageLogo.file.type,
            file: homepageLogo.path
          }
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        }
      });
      setIsWaitingResponse(false);
      setMessage('Success!');
    } catch (err) {
      setIsWaitingResponse(false);
      setMessage(
        'Error. Image might not meet standards. Try again or contact DEV'
      );
    }
  };
  return (
    <div>
      <h1>Sponsors Administration</h1>
      <p>Delete feature is on the works. Contact Dev to delete a sponsor</p>
      <p>Scroll down to add a new sponsor</p>
      <h2>Current Sponsors</h2>
      {currentSponsors.map(({ logo, name, info, link, homepage_logo }) => (
        <div>
          <Tile key={name} logo={logo} name={name} info={info} link={link} />
          <div className="homepage__logo__container">
            <h3>Home page logo</h3>
            <div className="homepage__logo">
              <img src={homepage_logo} alt="" />
            </div>
          </div>
        </div>
      ))}
      {!isWaitingResponse && !message ? (
        <div>
          <h2>Add New Sponsor</h2>
          <Tile
            logo={imagePreview.path}
            name={company}
            info={inputText}
            link={inputLink}
          />
          <div className="homepage__logo__container">
            <h3>Home page logo</h3>
            <div className="homepage__logo">
              <img src={homepageLogo.path} alt="" />
            </div>
          </div>
          <br />
          <label>Sponsors Page Image</label>
          <input type="file" onChange={(event) => imageHandler(event)} />
          <label>Homepage Sponsor Image</label>
          <input
            type="file"
            onChange={(event) => homepageImageHandler(event)}
          />
          <div className="sponsor__text__user__input">
            <input
              type="text"
              name="company"
              onChange={handleInputChange()}
              value={company}
              placeholder="input company"
            />
            <input
              type="text"
              name="text"
              onChange={handleInputChange(0)}
              value={inputText[0]}
              placeholder="input paragraph 1"
            />
            <input
              type="text"
              name="text"
              onChange={handleInputChange(1)}
              value={inputText[1]}
              placeholder="input paragraph 2"
            />
            <input
              type="text"
              name="link"
              onChange={handleInputChange()}
              value={inputLink}
              placeholder="input link"
            />
            {inputText && inputLink && company && homepageLogo.path && (
              <button onClick={(event) => onSubmit(event)}>Submit</button>
            )}
          </div>
        </div>
      ) : isWaitingResponse && !message ? (
        <div className="loader"></div>
      ) : (
        message && <div className="success__message">{message}</div>
      )}
    </div>
  );
};

export default SponsorsDashbord;
