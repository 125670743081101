import React from 'react';

import './NavBar.scss';
import { cognitoSignOut } from './../auth/cognito';
import dgacLogo from './../assets/dgac_logo.png';

const NavBar = ({ ...props }) => {
  const onLogOut = (event) => {
    event.preventDefault();
    cognitoSignOut(props.setAuthenticatedUser);
  };

  const turnOffState = () => {
    props.setDisplayGreeting(false);
    props.setDisplayDealers(false);
    props.setDisplayVolunteers(false);
    props.setDisplayPress(false);
    props.setDisplaySponsors(false);
    props.setDisplayStats(false);
  };

  const onDealers = (event) => {
    event.preventDefault();
    turnOffState();
    props.setDisplayDealers(true);
  };

  const onVolunteers = (event) => {
    event.preventDefault();
    turnOffState();
    props.setDisplayVolunteers(true);
  };

  const onPress = (event) => {
    event.preventDefault();
    turnOffState();
    props.setDisplayPress(true);
  };

  const onSponsors = (event) => {
    event.preventDefault();
    turnOffState();
    props.setDisplaySponsors(true);
  };

  const onStats = (event) => {
    event.preventDefault();
    turnOffState();
    props.setDisplayStats(true);
  };

  return (
    <div className="navbar__container">
      <div className="dgac__logo">
        <img src={dgacLogo} alt="Do-Good Auto Coalition Logo" />
      </div>
      <div className="navigation__buttons__container">
        <div className="navigation__buttons">
          <button onClick={(event) => onStats(event)}>Stats</button>
          <button onClick={(event) => onSponsors(event)}>Sponsors</button>
          <button onClick={(event) => onPress(event)}>Press</button>
          <button onClick={(event) => onDealers(event)}>Dealers</button>
          <button onClick={(event) => onVolunteers(event)}>Volunteers</button>
          <button onClick={(event) => onLogOut(event)}>Log Out</button>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
