import React, { useState } from 'react';
import CALogo from './../assets/ca_logo.png';
import './Verify.scss';

import { cognitoSignUp } from './../auth/cognito';

const Login = ({ setAuthenticatedUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');

  const onSubmit = async (event) => {
    event.preventDefault();
    if (passwordConfirm !== password) {
      setError("passwords don't match");
      setPasswordConfirm('');
      setPassword('');
    }
    cognitoSignUp({ email, verificationCode, password }, callback);
    setEmail('');
    setPassword('');
    setPasswordConfirm('');
    setVerificationCode('');
  };

  const callback = {
    error: setError,
    setAuthentication: setAuthenticatedUser
  };

  const handleChange = () => (event) => {
    event.preventDefault();
    event.target.name === 'email'
      ? setEmail(event.target.value)
      : event.target.name === 'code'
      ? setVerificationCode(event.target.value)
      : event.target.name === 'password'
      ? setPassword(event.target.value)
      : setPasswordConfirm(event.target.value);
  };

  return (
    <div className="login__section__container">
      <div className="logo__section">
        <div className="ca__logo__container">
          <img src={CALogo} alt="Constellation Agency Logo" />
        </div>
        <div className="signup__admin__text">
          <h1>DGAC Admin Signup</h1>
          <form onSubmit={onSubmit}>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              onChange={handleChange()}
              value={email}
              required
            />
            <br />
            <label>Verification Code:</label>
            <input
              type="password"
              name="code"
              onChange={handleChange()}
              value={verificationCode}
              required
            />
            <br />
            <label>Password:</label>
            <input
              type="password"
              name="password"
              onChange={handleChange()}
              value={password}
              placeholder="require numbers, upper AND lower case letters, 8 characters long"
              required
            />
            <br />
            <label>Confirm Password:</label>
            <input
              type="password"
              name="passwordConfirm"
              onChange={handleChange()}
              value={passwordConfirm}
              placeholder="require numbers, upper AND lower case letters, 8 characters long"
              required
            />
            <br />
            <button type="submit">Sign Up</button>
            <p>
              Don't have verification code? Contact the Dev team for support
            </p>
          </form>
          {error && <p>{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Login;
