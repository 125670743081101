import React, { useState, useEffect } from 'react';
import { getIdToken } from './../../auth/cognito';

import './StatsDashboard.scss';

const AWS_API_CLIENT = process.env.REACT_APP_AWS_CLIENT_API;
const AWS_API_ADMIN = process.env.REACT_APP_AWS_ADMIN_API;

const StatsDashboard = () => {
  const [stats, setStats] = useState([]);
  const [food, setFood] = useState('');
  const [participants, setParticipants] = useState('');
  const [meals, setMeals] = useState('');
  const [missions, setMissions] = useState('');
  const [assistance, setAssistance] = useState('');
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${AWS_API_CLIENT}/stats`);
        await response.json().then((res) => setStats(res));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const onInputChange = (event) => {
    event.persist();
    if (event.target.name === 'food') {
      setFood(event.target.value);
    } else if (event.target.name === 'meals') {
      setMeals(event.target.value);
    } else if (event.target.name === 'missions') {
      setMissions(event.target.value);
    } else if (event.target.name === 'participants') {
      setParticipants(event.target.value);
    } else {
      setAssistance(event.target.value);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = await getIdToken();
      await fetch(`${AWS_API_ADMIN}/stats`, {
        method: 'POST',
        body: JSON.stringify({
          food: { value: food, name: 'food' },
          participants: { value: participants, name: 'participants' },
          meals: { value: meals, name: 'meals' },
          missions: { value: missions, name: 'missions' },
          assistance: { value: assistance, name: 'assistance' }
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        }
      });
      setIsWaitingResponse(false);
      setParticipants('');
      setMeals('');
      setMissions('');
      setFood('');
      setMessage('Success!');
    } catch (err) {
      setIsWaitingResponse(false);
      setMessage('Error: Log out and try again. Contact Dev for more support');
    }
  };

  return (
    <div>
      <h1>Stats Administration</h1>
      <h2>Current Stats</h2>
      {stats.length === 0 ? (
        <div className="loader"></div>
      ) : (
        <div className="current__stats">
          {stats.map(({ name, count }) => (
            <div key={name} className="single__stat">
              <h3>{name}</h3>
              <p>{count}</p>
            </div>
          ))}
        </div>
      )}
      <div className="new__stats__container">
        <h2>New Stats</h2>
        <p>
          Please note that you are responsible of keeping the format, inlucing
          "," (commas) on numbers and the "+" (plus) sign
        </p>
        <p>
          Current design suggest that each section{' '}
          <strong>
            <i>should not contain more than 7 characters</i>
          </strong>{' '}
          including "," and "+"
        </p>
        <div className="custom__input__field">
          <label>New Participants Count</label>
          <input
            type="text"
            name="participants"
            value={participants}
            onChange={(event) => onInputChange(event)}
          />
          <div>
            {participants.length === 0 ? (
              ''
            ) : participants.length > 7 ? (
              <span>{participants.length}</span>
            ) : (
              participants.length
            )}
          </div>
        </div>
        <div className="custom__input__field">
          <label>New Missions Count</label>
          <input
            type="text"
            name="missions"
            value={missions}
            onChange={(event) => onInputChange(event)}
          />
          <div>
            {missions.length === 0 ? (
              ''
            ) : missions.length > 7 ? (
              <span>{missions.length}</span>
            ) : (
              missions.length
            )}
          </div>
        </div>
        <div className="custom__input__field">
          <label>New Assistance Requested</label>
          <input
            type="text"
            name="assistance"
            value={assistance}
            onChange={(event) => onInputChange(event)}
          />
          <div>
            {assistance.length === 0 ? (
              ''
            ) : assistance.length > 7 ? (
              <span>{assistance.length}</span>
            ) : (
              assistance.length
            )}
          </div>
        </div>
        <div className="custom__input__field">
          <label>New Food Count</label>
          <input
            type="text"
            name="food"
            value={food}
            onChange={(event) => onInputChange(event)}
          />
          <div>
            {food.length === 0 ? (
              ''
            ) : food.length > 7 ? (
              <span>{food.length}</span>
            ) : (
              food.length
            )}
          </div>
        </div>
        <div className="custom__input__field">
          <label>New Meals Count</label>
          <input
            type="text"
            name="meals"
            value={meals}
            onChange={(event) => onInputChange(event)}
          />
          <div>
            {meals.length === 0 ? (
              ''
            ) : meals.length > 7 ? (
              <span>{meals.length}</span>
            ) : (
              meals.length
            )}
          </div>
        </div>
        {!isWaitingResponse && !message ? (
          <button onClick={(event) => onSubmit(event)}>SUBMIT</button>
        ) : isWaitingResponse && !message ? (
          <div className="loader"></div>
        ) : (
          <div>{message}</div>
        )}
      </div>
    </div>
  );
};

export default StatsDashboard;
