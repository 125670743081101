import React, { useState } from 'react';

import EditDealers from '../EditDealers';
import AddDealers from '../AddDealers';

import './DealersDashboard.scss';

const DealersDashboard = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const toggleViews = (event, view) => {
    event.preventDefault();
    if (view === 'edit') {
      setShowAdd(false);
      setShowEdit(true);
    } else {
      setShowEdit(false);
      setShowAdd(true);
    }
  };
  return (
    <div className="dealers__dashboard">
      <h1>Dealers Administration</h1>
      <div className="dealers__toggle__views">
        <button onClick={(event) => toggleViews(event, 'edit')}>Edit</button>
        <button onClick={(event) => toggleViews(event, 'add')}>Add</button>
      </div>
      {showEdit && <EditDealers />}
      {showAdd && <AddDealers />}
    </div>
  );
};

export default DealersDashboard;
