import React from 'react';

import './SponsorTile.scss';

const SponsorsTile = ({ logo, name, info, link }) => {
  return (
    <div className="sponsors_container">
      <div className="single_sponsor">
        <div className="logo_content">
          <a href={link}>
            {' '}
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="single_sponsor_content">
          <p className="sponsor_name">{name}</p>
          {info.map((paragraph, idx) => (
            <p className="paragraph" key={idx}>
              {paragraph}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SponsorsTile;
