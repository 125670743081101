import React, { useState, useEffect } from 'react';
import EditableTiles from './EditableTiles';
import { getIdToken } from './../auth/cognito';
import './EditDealers.scss';

const AWS_API_CLIENT = process.env.REACT_APP_AWS_CLIENT_API;
const AWS_API_ADMIN = process.env.REACT_APP_AWS_ADMIN_API;

const EditDealers = () => {
  const [dealers, setDealers] = useState([]);
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [message, setMessage] = useState('');

  const newDealerTemplate = { name: '' };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${AWS_API_CLIENT}/dealers`);
        await response.json().then((res) => {
          const editableAttrs = res.map((item) => ({
            ...item,
            requested_edit: false,
            editable_type: '',
            new_subdealers: [],
            message: ''
          }));
          setDealers(editableAttrs);
        });
      } catch (err) {
        setIsWaitingResponse(false);
        setMessage(
          'Error: Log out and try again. Contact Dev for more support'
        );
      }
    };
    fetchData();
  }, []);

  const handleOnChange = (event, dealerIdx, subdealerIdx) => {
    event.persist();
    setDealers((prevState) => [
      ...prevState.slice(0, dealerIdx),
      {
        ...prevState[dealerIdx],
        new_subdealers: [
          ...prevState[dealerIdx].new_subdealers.slice(0, subdealerIdx),
          {
            name: event.target.value
          },
          ...prevState[dealerIdx].new_subdealers.slice(subdealerIdx + 1)
        ]
      },
      ...prevState.slice(dealerIdx + 1)
    ]);
  };

  const handleOnEditDealers = (event, idx, editType) => {
    event.preventDefault();
    if (editType === 'add') {
      setDealers((prevState) => [
        ...prevState.slice(0, idx),
        {
          ...prevState[idx],
          requested_edit: true,
          editable_type: 'add',
          message: '**This tile will be edited**',
          new_subdealers: [...prevState[idx].new_subdealers, newDealerTemplate]
        },
        ...prevState.slice(idx + 1)
      ]);
    } else {
      setDealers((prevState) => [
        ...prevState.slice(0, idx),
        {
          ...prevState[idx],
          requested_edit: true,
          editable_type: 'delete',
          message: '**This tile will be deleted**'
        },
        ...prevState.slice(idx + 1)
      ]);
    }
  };

  const confirm = (deleteLength, addLength) => {
    if (
      window.confirm(
        `You are about to delete ${deleteLength} dealers, and edit ${addLength} subdealers. Do you want to proceed?`
      )
    )
      return true;
    return false;
  };
  const onSubmit = async (event) => {
    event.preventDefault();
    setIsWaitingResponse(true);
    let addSubdealers = [];
    let deleteDealers = [];

    dealers.forEach((dealer) => {
      if (dealer.editable_type === 'add') {
        const newSubdealers = dealer.new_subdealers.map(
          (subdealer) => subdealer.name
        );
        addSubdealers = [
          ...addSubdealers,
          {
            id: dealer.id,
            sub_dealers: [...dealer.sub_dealers, ...newSubdealers]
          }
        ];
      } else if (dealer.editable_type === 'delete') {
        deleteDealers = [...deleteDealers, { id: dealer.id }];
      }
    });

    if (confirm(deleteDealers.length, addSubdealers.length)) {
      try {
        const token = await getIdToken();
        await fetch(`${AWS_API_ADMIN}/dealers/edit`, {
          method: 'POST',
          body: JSON.stringify({
            new_subdealers: addSubdealers,
            delete_dealers: deleteDealers
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`
          }
        });
        setIsWaitingResponse(false);
        setMessage('Success!');
      } catch (err) {
        setIsWaitingResponse(false);
        setMessage(
          'Error: Log out and try again. Contact Dev for more support'
        );
      }
    }
  };
  return (
    <div className="dealers__container">
      <p>
        To edit <b>MAIN DEALER</b> or delete a subdealer, you need to delete the
        entire tile and add it again
      </p>
      <p>To discard changes, refresh page</p>
      {!isWaitingResponse && !message ? (
        <div>
          <div className="editable__dealers">
            {dealers.map(
              (
                {
                  id,
                  sub_dealers,
                  requested_edit,
                  new_subdealers,
                  message,
                  editable_type
                },
                idx
              ) => (
                <EditableTiles
                  key={id}
                  id={id}
                  type="edit"
                  sub_dealers={sub_dealers}
                  title="Executive Sponsor"
                  subtitle="Dealers"
                  index={idx}
                  requested_edit={requested_edit}
                  new_subdealers={new_subdealers}
                  message={message}
                  editable_type={editable_type}
                  handleChange={handleOnChange}
                  handleEdit={handleOnEditDealers}
                />
              )
            )}
          </div>
          <div className="submit__section">
            <button onClick={(event) => onSubmit(event)}>Submit</button>
          </div>
        </div>
      ) : isWaitingResponse && !message ? (
        <div className="loader"></div>
      ) : (
        message && <div className="success__message">{message}</div>
      )}
    </div>
  );
};

export default EditDealers;
