import React, { useState, useEffect } from 'react';
import { getIdToken } from './../auth/cognito';
import Tile from './PressTile';

import './PressDelete.scss';

const AWS_API_CLIENT = process.env.REACT_APP_AWS_CLIENT_API;
const AWS_API_ADMIN = process.env.REACT_APP_AWS_ADMIN_API;

const NewsDelete = () => {
  const [news, setNews] = useState([]);
  const [deletedNews, setDeletedNews] = useState([]);
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${AWS_API_CLIENT}/press`);
        await response.json().then((res) => {
          const newsWithAttrs = res.map((item) => ({
            ...item,
            requested_delete: false
          }));
          setNews(newsWithAttrs);
        });
      } catch (err) {
        setIsWaitingResponse(false);
        setMessage(
          'Error: Log out and try again. Contact Dev for more support'
        );
      }
    };
    fetchData();
  }, []);

  const onDelete = (event, idx) => {
    event.preventDefault();
    setNews((prevState) => [
      ...prevState.slice(0, idx),
      {
        ...prevState[idx],
        requested_delete: true
      },
      ...prevState.slice(idx + 1)
    ]);
    setDeletedNews((prevState) => [...prevState, news[idx]]);
  };

  const confirm = (deletedLenght) => {
    if (
      window.confirm(
        `You are about to delete ${deletedLenght} press. Do you want to proceed?`
      )
    )
      return true;
    return false;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsWaitingResponse(true);
    if (confirm(deletedNews.length)) {
      try {
        const token = await getIdToken();
        await fetch(`${AWS_API_ADMIN}/press`, {
          method: 'DELETE',
          body: JSON.stringify({ deletedNews }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`
          }
        });
        setIsWaitingResponse(false);
        setMessage('Success!');
      } catch (err) {
        setIsWaitingResponse(false);
        setMessage(
          'Error: Log out and try again. Contact Dev for more support'
        );
      }
    }
  };
  return (
    <div className="delete__press__section">
      <h2>Delete</h2>
      <p>Deletion won't happen until you click submit</p>
      {!isWaitingResponse && !message ? (
        <div>
          <div className="delete__press__container">
            {news.map(
              (
                { id, image_url, text, link, publisher, requested_delete },
                idx
              ) => {
                return (
                  !requested_delete && (
                    <div className="delete__press__tile" key={id}>
                      <Tile
                        image_url={image_url}
                        text={text}
                        link={link}
                        publisher={publisher}
                      />
                      <div className="delete__press__button">
                        <button onClick={(event) => onDelete(event, idx)}>
                          Delete
                        </button>
                      </div>
                    </div>
                  )
                );
              }
            )}
          </div>
          <button onClick={(event) => onSubmit(event)}>Submit</button>
        </div>
      ) : isWaitingResponse && !message ? (
        <div className="loader"></div>
      ) : (
        message && <div className="success__message">{message}</div>
      )}
    </div>
  );
};

export default NewsDelete;
