import React, { useState } from 'react';

import PressDelete from './../PressDelete';
import PressAdd from './../PressAdd';

import './NewsDashboard.scss';

const NewsDashboard = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const toggleViews = (event, view) => {
    event.preventDefault();
    if (view === 'delete') {
      setShowAdd(false);
      setShowDelete(true);
    } else {
      setShowDelete(false);
      setShowAdd(true);
    }
  };
  return (
    <div className="news__dashboard__section">
      <h1>Press Administration</h1>
      <div className="news__toggle__views">
        <button onClick={(event) => toggleViews(event, 'delete')}>
          Delete
        </button>
        <button onClick={(event) => toggleViews(event, 'add')}>Add</button>
      </div>
      {showDelete && <PressDelete />}
      {showAdd && <PressAdd />}
    </div>
  );
};

export default NewsDashboard;
